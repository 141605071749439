<template>
    <div>
        <div>{{i18nT(`Debugger`)}}</div>
        <h1>Hello Roumen!</h1>
        <h2>Font awesome!</h2>
        <p>Have a cup of coffee: <font-awesome-icon icon="coffee" /></p>
        <p>Take a break: <font-awesome-icon icon="moon" /></p>
        <div class="clearfix bshadow0 pbs">
            <span class="icomoon-activity"></span>
            <span class="icomoon-twitter"></span>
            <span class="icomoon-instagram"></span>
            <span class="mls"> icon-activity</span>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    directives: {},
    props: {},
    methods: {},
}
</script>
